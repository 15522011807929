import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import TagManager from 'react-gtm-module'

// History Change Trigger might be needed in case of #URI usage
// https://medium.com/finnovate-io/integrating-google-tag-manager-with-a-react-app-5a8584ee2251
if (process.env.NODE_ENV !== "development") {
  const tagManagerArgs = {
      gtmId: 'GTM-PKQB8HL'
  }
  TagManager.initialize(tagManagerArgs)
}

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);