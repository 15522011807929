import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import MainFeature3 from "components/features/HeadingAndImageSimple";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"

import TestimonialImage1 from "images/testimonials-1.avif";
import TestimonialImage2 from "images/testimonials-2.avif";
import TestimonialImageGeneral1 from "images/testimonials-general-1.avif";
import TestimonialImageGeneral2 from "images/testimonials-general-2.avif";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature 
      imageSrc={serverSecureIllustrationImageSrc}
    />
    <Features />
    <MainFeature2 />
    <Portfolio />
    {/* <Pricing /> */}
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Here are what some of our amazing customers are saying about our marketing professionals. These clients approached us and we effectively helped bring their fantastic visions to life."
      testimonials={[
        {
          imageSrc:
            TestimonialImageGeneral1,
          profileImageSrc:
            TestimonialImage1,
          quote:
            "We thank Havicus for the wonderful job in helping us develop our finance platform. Everyone was professional, devoted and hard working. Thanks to them, we were able to achieve our goal on time, and we look forward to continue working with them in the future.",
          customerName: "Erik Reimund",
          customerTitle: "Chief Operating Officer (COO), FinTech Company"
        },
        {
          imageSrc:
            TestimonialImageGeneral2,
          profileImageSrc:
            TestimonialImage2,
          quote:
            "I wanted to take a moment to thank you for the services your team has provided. Your team has been a pleasure to work with, professional and timely. Martin, Maria and the developmend team pulled it off. Job well done and I hope we can continue to grow together...",
          customerName: "Arvid Mathys",
          customerTitle: "Chief Technology Officer (CTO), ANET"
        }
      ]}
      textOnLeft={true}
    />
    <MainFeature3 />    
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    {/* <Blog /> */}
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
