import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import StatsIllustrationSrc from "images/stats-illustration.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import Partners from "images/partners.avif";

const Container = tw.div`relative`;
const Column = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto pt-8 md:pt-10 pb-12 md:pb-16`;
// const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const TextContent = tw.div`lg:py-8 text-center md:text-center`;

const Subheading = tw(SubheadingBase)`text-center md:text-center mx-auto mb-6`;

export default ({
  subheading = "Our Partners",
  heading = (
    <>
      We have over <span tw="text-primary-500">40</span> years <wbr /> of combined experience. 
    </>
  ),
  description = "We are a multidisciplinary custom software development team that designs tailor-made solutions for your needs. Havicus has been creating impactful custom-built software for various companies for years across industries. We rely on our profound expertise, technological vision, and streamlined processes to provide end-to-end custom software development and turn technological solutions into growth enablers.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "#testimonial",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  //Change the statistics variable as you like, add or delete objects
  const defaultStatistics = [
    {
      key: "Clients",
      value: "32+"
    },
    {
      key: "Projects",
      value: "100+"
    },
    {
      key: "Awards",
      value: "18+"
    }
  ];

  if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <Column>
        <TextContent>
          {subheading && <Subheading>{subheading}</Subheading>}
          <img src={Partners} css={imageCss} alt="partners" />
        </TextContent>

      </Column>
    </Container>
  );
};
